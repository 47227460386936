<template>
  <div class="home">
    <img src="https://mamab.jp/uploads/web_image/senior_mv.png" />
    <Form ref="form" v-slot="observer">
      <div class="title">
        <img src="https://mamab.jp/uploads/web_image/senior_form-h.png" />
      </div>
      <Field
        class="inputs"
        name="email"
        label="メールアドレス"
        rules="required|dependency_string|email_rfc"
        v-slot="{ field, errors, meta }"
        v-model="data.email"
        :validateOnInput="true"
        as="div"
      >
        <div :class="{ ok: meta.valid }">
          <input
            type="text"
            :class="{ next: meta.valid }"
            v-bind="field"
            placeholder="メールアドレスを入力してください"
          />
        </div>
        <ul v-if="errors.length > 0" class="input-errors">
          <li>{{ errors[0] }}</li>
        </ul>
      </Field>
      <privacy-policy-notice
        class="notice"
        src="https://corp.karadanote.jp/privacy-handling-general"
      >
      </privacy-policy-notice>
      <start-button
        :animation="observer.meta.valid"
        imgSrc="https://mamab.jp/uploads/web_image/senior_cvbtn.png"
        :on-click="next"
        :class="{ disabled: isConnectingStep1 }"
      >
      </start-button>
    </Form>
    <div class="bg-white">
      <section class="lottery">
        <div>
          <img src="https://mamab.jp/uploads/web_image/senior_pal-system.png" />
        </div>
        <div class="slide-in" :class="{ show: show.lotteryPresent }">
          <img src="https://mamab.jp/uploads/web_image/senior_h2.png" />
        </div>
        <div>
          <img src="https://mamab.jp/uploads/web_image/senior_pre.png" />
        </div>
      </section>
    </div>
    <section class="recommend">
      <div>
        <img src="https://mamab.jp/uploads/web_image/senior_pre-details.png" />
      </div>
      <div>
        <img src="https://mamab.jp/uploads/web_image/senior_campaign.png" />
      </div>
    </section>
    <guideline title="ご応募について">
      <div>
        ご応募はお1人様1回限り有効です。二重応募された場合は、初めにご応募いただいたキャンペーンのみ適用となります。またプレゼント品が変更になっても2回目以降のご応募は出来ませんのでご了承ください。
      </div>
      <div class="sub-title">プレゼント情報</div>
      <div>
        カラダノートからお届けします。
        <div class="annotation">
          ※プレゼントの色・柄などはお選びいただけません<br />
          ※プレゼントの内容は予告なく変更する場合があります<br />
          ※ご住所不明、長期ご不在、その他の理由によりプレゼントをお届けできない場合、ご応募を取り消す場合があります<br />
          ※当選者の都合による賞品の交換、返品はできません。（不良品、欠損品などの場合は除く）<br />
          ※またプレゼントの不具合・不良品等による保証等は、発売元・メーカーの保証に従います。<br />
          ※プレゼントに関する品質・性能・不具合等は、一切責任を負いかねます。予めご了承ください。<br />
          ※配送中の事故等、やむを得ない事情によって賞品の破損等が発生した場合、同じ賞品を代替品としてご用意できない場合がございます。予めご了承ください。
        </div>
      </div>
      <div class="sub-title">抽選について</div>
      <div>
        応募期限：{{ limitYearDate }}
        <div class="annotation">
          ※当選者発表は商品の発送をもってかえさせていただきます。
        </div>
      </div>
    </guideline>
    <guideline title="その他のご案内">
      ・申込み確認メールの受信エラー防止のため、
      @mm.karadanote.jpの受信ができるように設定をお願いします<br />
      ・何らかの事情で応募が完了していないお客様には、お知らせメールを配信させていただく場合がございます<br />
      ・新着の商品/プレゼント情報、お得に購入できるクーポン案内などをお送りさせていただく場合がございます<br />
      ・本キャンペーンは株式会社カラダノートが主催しており、Apple社は関係ありません<br />
      ・アンケートの回答内容によって協賛企業よりご案内をさせていただく場合がございます。この機会にご検討ください<br />
      ・プレゼントの仕様・デザイン・品揃えはメーカーなどの都合により、予告なく変更することがあります
      <sponsor-list :names="sponsors.names"></sponsor-list>
    </guideline>
    <floating-start-button
      :visible="show.floatBtn"
      img-src="https://mamab.jp/uploads/web_image/cvbtn.png"
      :on-click="next"
    >
    </floating-start-button>
  </div>
</template>

<style lang="scss" scoped>
.slide-in {
  opacity: 0;
  transform: translateY(40px);
  transition: all 1s;
  margin-bottom: 0.4rem;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
  + div + div {
    margin-top: 2rem;
  }
}
input[name="email"] {
  border: 2.5px solid #e0171e;
  animation-name: nextInput;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  height: 70px;
  margin-top: 0;

  &:focus,
  &.next {
    animation-name: none;
  }
}
.notice {
  margin-top: 10px;
  margin-bottom: 20px;
}
.bg-white {
  padding: 0 1rem 2rem;
}
.recommend {
  background-color: #fff;
}
.sub-title {
  color: #ff9595;
  font-size: 0.8rem;
  border-left: 4px solid #ffdbdb;
  font-weight: bold;
  padding-left: 0.6rem;
  margin-top: 1rem;
  margin-bottom: 4px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>

<script>
import dayjs from "dayjs";
import "dayjs/locale/ja";
import PrivacyPolicyNotice from "@/components/Home/PrivacyPolicyNotice";
import FloatingStartButton from "@/components/Home/FloatingStartButton";
import StartButton from "@/components/Home/StartButton";
import SponsorList from "@/components/Home/SponsorList";
import Guideline from "@/components/Home/Guideline";
import MarketingCloud from "@/components/MarketingCloud.vue";
import sponsors from "@/assets/senior_sponsors.json";
import { scroll } from "@/util/util";

dayjs.locale("ja");
export default {
  name: "Home",
  components: {
    PrivacyPolicyNotice,
    FloatingStartButton,
    StartButton,
    SponsorList,
    Guideline,
  },
  mixins: [MarketingCloud],
  data() {
    return {
      sponsors,
      isConnectingStep1: false,
      animation: false,
      data: {
        email: "",
      },
      show: {
        lotteryPresent: false,
        floatBtn: false,
      },
      today: dayjs(), // UTしやすいように変数化
    };
  },
  beforeCreate() {
    const query = this.$route.query;
    const data = {};
    const route = [];
    if (query.utm_source) {
      route.push(`utm_source=${query.utm_source}`);
    }
    if (query.utm_medium) {
      route.push(`utm_medium=${query.utm_medium}`);
    }
    if (route.length > 0) {
      Object.assign(data, {
        route: route.join(","),
      });
    }

    const ua = window.navigator.userAgent;
    if (ua.includes("MamabKey/")) {
      const match = ua.match(/MamabKey\/([\w-]+);/);
      const match2 = ua.match(/MamabApp\/(\w+);/);

      Object.assign(data, {
        app_key: match[1],
        send_app_type: match2[1],
      });
    }
    this.$store.dispatch("answer/answer", data);
  },
  mounted() {
    // 過去に応募済みであればmypageへリダイレクト
    const campaign = this.$store.getters["campaign/getCampaign"];
    if (campaign.applied) {
      this.$router.push("mypage");
      return;
    }

    window.addEventListener("scroll", this.handleScroll);

    // ブラウザバックで遷移した際に再度タグを読み込み直す
    const el = document.getElementById("MC_tag_id");
    el !== null && el.remove();
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("id", "MC_tag_id");
    recaptchaScript.setAttribute(
      "src",
      "//514008293.collect.igodigital.com/collect.js"
    );
    document.head.appendChild(recaptchaScript);

    // トラッキング(MarketingCloud)
    this.mcPageView();
  },
  computed: {
    limitYearDate() {
      const nowDate = this.today;
      const diffMonths = [3, 2, 1, 0, 5, 4, 3, 2, 1, 0, 5, 4][nowDate.month()];
      const deadLine = nowDate
        .add(diffMonths, "month")
        .endOf("month")
        .format("YYYY年M月D日(ddd)");
      return deadLine;
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    next() {
      // 応募ボタン複数回連打対応
      if (this.isConnectingStep1) {
        return;
      }
      this.isConnectingStep1 = true;

      this.$refs.form.validate().then((result) => {
        if (result.valid) {
          // トラッキング(MarketingCloud)
          this.mcTopApply(this.data.email);

          this.axios
            .post("/checkEmail", { email: this.data.email })
            .then((res) => {
              if (!res.data.duplicate && res.data.errors.length === 0) {
                this.$store.dispatch("answer/answer", this.data);
                this.$router.push("step1");
              } else {
                alert(res.data.errors[0]);
              }
            })
            .catch((err) => {
              if (err.response.data.duplicate) {
                this.$store.dispatch("campaign/applied");
                alert("既にご応募済みです");
                this.$router.push("mypage");
              } else {
                this.isConnectingStep1 = false;
                alert(
                  "システムエラーのため、応募データの保存に失敗しました。お時間をあけて再度お試しください"
                );
              }
            });
        } else {
          this.isConnectingStep1 = false;
          scroll("form");
        }
      });
    },
    handleScroll() {
      const arys = [{ class: "lottery", key: "lotteryPresent" }];

      this.show.floatBtn =
        document.querySelector(".to-step1-btn").getBoundingClientRect().bottom <
          0 &&
        document.body.getBoundingClientRect().bottom - 40 > window.innerHeight;

      arys.forEach((obj) => {
        const element = document.querySelector("." + obj.class);
        if (
          !this.show[obj.key] &&
          element &&
          element.getBoundingClientRect().top < 500
        ) {
          this.show[obj.key] = true;
        }
      });
    },
  },
};
</script>
